import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import Layout from "../layouts"
import Container from "../components/container"
import Section from "../components/section"
import FeaturesSection from "../components/features"
import HomeFeaturesSection from "../components/features/home"
import Header from "../components/header"
import Footer from "../components/footer"
import ContentBlock from "../components/content-block"
import ContactUsForm from "../components/contact-us-form"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Webinar from "../components/webinar"

import { getLayout } from "../utils/layout"
import { guideContentBlockStyles, articleStyles } from "../utils/styles"

const DefaultPage = ({ data, location, pageContext }) => {
  const {
    contentfulPage,
    heroImage,
    nav,
    homepageHeroImage,
    allContentfulLandingPage,
  } = data
  const { name, sections, contentBlocks, showContactForm } = contentfulPage
  const layout = getLayout(location.pathname)
  const isHome = location.pathname === `/`
  const isWebinars = location.pathname === `/resources/webinars/`
  const webinars = allContentfulLandingPage.edges
    .map(page => page.node)
    .sort((a, b) => a.createdAt - b.createdAt)

  return (
    <Layout pathname={location.pathname}>
      <SEO contentfulPage={contentfulPage} />
      <Header navItems={nav.edges} isInverted={layout.inverted} />
      <Hero
        image={heroImage && heroImage.childImageSharp}
        homepageHeroImage={
          homepageHeroImage && homepageHeroImage.childImageSharp
        }
        layout={layout}
        page={contentfulPage}
      />
      {(sections || []).map((section, i) =>
        section.featuresList ? (
          <div key={i}>
            {isHome ? (
              <HomeFeaturesSection section={section} key={i} />
            ) : (
              <FeaturesSection section={section} key={i} />
            )}
          </div>
        ) : (
          <Container key={i}>
            <Section section={section} page={name} />
          </Container>
        )
      )}
      <Container>
        <div css={{ ...articleStyles }}>
          {contentBlocks &&
            contentBlocks.map(cb => (
              <div key={cb.id} css={guideContentBlockStyles}>
                <ContentBlock contentBlock={cb} />
              </div>
            ))}
        </div>
        {isWebinars &&
          (webinars || []).map((webinar, i) => (
            <Webinar webinar={webinar} key={i} />
          ))}
        {showContactForm ? <ContactUsForm path={pageContext.path} /> : null}
      </Container>
      <Footer navItems={nav.edges} />
    </Layout>
  )
}

DefaultPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default DefaultPage

export const pageQuery = graphql`
  query ContentfulPage($id: String!, $heroBackgroundImage: String) {
    ...MainNavigation
    ...HomepageHeroImage
    heroImage: file(relativePath: { regex: $heroBackgroundImage }) {
      childImageSharp {
        fluid(
          maxWidth: 2000
          duotone: { highlight: "#663399", shadow: "#241236" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      parentPage {
        name
        slug
        parentPage {
          name
          slug
        }
      }
      keyvisual {
        name
      }
      showContactForm
      ...ContentBlocks
      ...SocialMediaImage
      sections {
        title
        linkText
        associatedPage {
          name
          slug
          parentPage {
            name
            slug
          }
        }
        layout
        text {
          childMarkdownRemark {
            html
          }
        }
        keyvisual {
          name
        }
        ...FeaturesListFragment
        ...LogoBoxesFragment
      }
    }
    allContentfulLandingPage(
      filter: { landingPageType: { in: ["Webinar", "Webcast"] } }
    ) {
      ...WebinarFragment
    }
  }
`
