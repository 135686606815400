import React from "react"
import PropTypes from "prop-types"

import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SupportHeroBackground from "../decoration/bg-duotone"
import SkewedBackground from "../../components/decoration/skewed-background"
import PullBelowHeader from "../../components/containers/pull-below-header"
import { mediaQueries } from "../../utils/presets"

import Title from "./title"
import Subtitle from "./subtitle"

class Hero extends React.Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    image: PropTypes.object,
  }

  render() {
    const { page, image } = this.props

    return (
      <PullBelowHeader>
        <HeroContainer
          isInverted
          customStyles={{
            display: `flex`,
            background: `yellow`,
            minHeight: `calc(80vh+10vw)`,
            alignItems: `flex-end`,
            flexDirection: `row`,
            flexWrap: `wrap`,
            flexShrink: 0,
            flexGrow: 1,
            paddingBottom: `8vh`,
            [mediaQueries.phablet]: {
              minHeight: `calc(100vh)`,
            },
            [mediaQueries.hd]: {
              minHeight: `calc(100vh)`,
            },
          }}
        >
          <Container
            customStyles={{
              height: `100%`,
              alignSelf: `flex-end`,
              flexDirection: `column`,
              flexWrap: `wrap`,
              alignItems: `flex-start`,
            }}
          >
            <Title
              isInverted
              customStyles={{
                maxWidth: `80%`,
                [mediaQueries.phablet]: {
                  maxWidth: `60%`,
                },
              }}
            >
              {page.title}
            </Title>
            {page.subtitle && (
              <Subtitle isInverted customStyles={{ maxWidth: `28rem` }}>
                {page.subtitle.subtitle}
              </Subtitle>
            )}
          </Container>
          <SupportHeroBackground image={image} />
          <SkewedBackground shape="wave" />
        </HeroContainer>
      </PullBelowHeader>
    )
  }
}

export default Hero
