import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FaGithub } from "react-icons/fa"
import hex2rgba from "hex2rgba"
import fmt from "human-format"

import { colors, fontSizes } from "../utils/presets"

const roundToNearest = (num, increment) =>
  Math.floor(num / increment) * increment

const GithubStats = () => (
  <StaticQuery
    query={graphql`
      query LoadGitHubData {
        github {
          repository(owner: "gatsbyjs", name: "gatsby") {
            url
            mentionableUsers {
              totalCount
            }
            stargazers {
              totalCount
            }
          }
        }
      }
    `}
    render={({ github: { repository } }) => (
      <a
        href={repository.url}
        title="GitHub"
        css={{
          fontSize: fontSizes[1],
          color: hex2rgba(colors.ui.bright, 0.5),
          textDecoration: `none`,
        }}
      >
        <FaGithub style={{ marginRight: `0.5rem` }} />
        {` `}
        {roundToNearest(repository.mentionableUsers.totalCount, 50)}+
        contributors,
        {` `}
        {fmt(repository.stargazers.totalCount, { decimals: 1 })} stars
      </a>
    )}
  />
)

export default GithubStats
