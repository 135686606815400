import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Img from "../../components/core/img"
import LazySVGImg from "../lazy-svg"

import Container from "../container"
import PullBelowHeader from "../containers/pull-below-header"
import SkewedBackground from "../../components/decoration/skewed-background"
import HeroContainer from "../containers/hero"
import GithubStats from "../github-stats"
import { Button, ButtonGroup } from "../core/button"
import { rhythm } from "../../utils/typography"
import { mediaQueries, sizes, colors, space } from "../../utils/presets"

import Title from "./title.js"
import Subtitle from "./subtitle.js"

class Hero extends React.Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    image: PropTypes.object,
  }

  render() {
    const { page, image } = this.props
    const breakpoint = mediaQueries.tablet

    return (
      <PullBelowHeader>
        <HeroContainer
          isInverted
          customStyles={{
            paddingBottom: space[13],
            background: colors.night,
            overflow: `hidden`,
          }}
        >
          <SkewedBackground shape="wave" />
          <div
            css={{
              position: `absolute`,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              [breakpoint]: {
                transform: `translateY(50%)`,
              },
            }}
          >
            <div
              css={{
                positon: `relative`,
                [mediaQueries.hd]: {
                  width: `90%`,
                  margin: `0 auto`,
                  maxWidth: `${sizes.layoutWidth}rem`,
                },
              }}
            >
              <div css={{ [breakpoint]: { position: `relative` } }}>
                <div
                  css={{
                    position: `absolute`,
                    left: 0,
                    right: 0,
                    pointerEvents: `none`,
                    userSelect: `none`,
                    opacity: 0.5,
                    [breakpoint]: {
                      opacity: 1,
                      left: `35%`,
                      transform: `translateX(10%)`,
                    },
                    "& .gatsby-image-wrapper": {
                      top: `50%`,
                      transform: `translateY(-50%)`,
                    },
                  }}
                >
                  <LazySVGImg
                    src="bg-home.svg"
                    alt=""
                    css={{
                      position: `absolute`,
                      transform: `translateY(-50%)`,
                      left: 0,
                      right: 0,
                      margin: 0,
                      width: `100%`,
                    }}
                  />
                  {image && <Img alt={``} fluid={image.fluid} />}
                </div>
              </div>
            </div>
          </div>
          <Container>
            <div>
              <Title
                isInverted
                customStyles={{
                  "& span": {
                    color: colors.white,
                  },
                  [mediaQueries.mobile]: {
                    maxWidth: rhythm(14),
                  },
                  [mediaQueries.phablet]: {
                    maxWidth: rhythm(17),
                  },
                  [mediaQueries.desktop]: {
                    maxWidth: rhythm(19),
                  },
                }}
              >
                {page.title}
              </Title>
              {page.subtitle && (
                <Subtitle
                  isInverted
                  customStyles={{
                    marginTop: 0,
                    maxWidth: rhythm(18),
                  }}
                >
                  {page.subtitle.subtitle}
                </Subtitle>
              )}
              <div>
                <ButtonGroup customStyles={{ justifyContent: `left` }}>
                  <Button to="https://www.gatsbyjs.org">Get Started</Button>
                  <Button to="/preview" buttonStyle="secondary">
                    Join the waitlist for Gatsby Preview
                  </Button>
                </ButtonGroup>
                <GithubStats />
              </div>
            </div>
          </Container>
        </HeroContainer>
      </PullBelowHeader>
    )
  }
}

export const HomepageHeroImageFragment = graphql`
  fragment HomepageHeroImage on Query {
    homepageHeroImage: file(relativePath: { regex: "/globe.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1228, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Hero
