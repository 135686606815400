import React, { Component } from "react"

import HubspotForm from "./hubspot-form"
import { colors, space } from "../utils/presets"

class ContactUsForm extends Component {
  render() {
    return (
      <div css={{ marginBottom: space[11], maxWidth: 800 }} id="contact-us">
        <h3>Get in Touch</h3>
        <HubspotForm
          portalId="4731712"
          formId="05ca01d7-486d-476d-ae3c-8a14038defd0"
          sfdcCampaignId="701f4000000UwWDAA0"
          wrapperCss={{
            backgroundColor: colors.purple[`50`],
            // "Yes/ No" radio buttons on /support
            ".inputs-list.multi-container": {
              "& .hs-form-radio": {
                display: `inline-flex`,
                alignItems: `center`,
                marginRight: space[6],
                marginTop: space[2],
              },
            },
          }}
        />
      </div>
    )
  }
}

export default ContactUsForm
