import React from "react"
import PropTypes from "prop-types"
import Img from "../core/img"

import { colors } from "../../utils/presets"

const WhyKeyvisual = ({ image }) => (
  <div
    css={{
      position: `absolute`,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      overflow: `hidden`,
      background: colors.gatsby,
      zIndex: 0,
      pointerEvents: `none`,
      userSelect: `none`,
      "& .gatsby-image-outer-wrapper": {
        height: `100%`,
        maxWidth: `100%`,
      },
      "&&": {
        "& .gatsby-image-wrapper": {
          position: `absolute !important`,
          bottom: 0,
          top: 0,
        },
        "& img": {
          objectPosition: `center top !important`,
        },
      },
    }}
  >
    {image && (
      <Img
        css={{ top: 0, bottom: 0, left: 0, right: 0, zIndex: -1 }}
        title={`Rocket Scientists`}
        fluid={image.fluid}
      />
    )}
  </div>
)

WhyKeyvisual.propTypes = {
  image: PropTypes.object.isRequired,
}

export default WhyKeyvisual
