import React from "react"
import PropTypes from "prop-types"
import hex2rgba from "hex2rgba"

import Container from "../../components/container"
import SkewedBackground from "../../components/decoration/skewed-background"
import Section from "./index"
import CTA from "./cta"
import Title from "../hero/title"

import { colors, mediaQueries, radii, space } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import { bodyCopy } from "../../utils/styles"

const styles = {
  featuresContainer: {
    background: `linear-gradient(180deg, ${hex2rgba(`#4A90E2`, 0.05)} 0, 
      ${hex2rgba(`#4A90E2`, 0.04)}
     100%)`,
    marginBottom: space[9],
    paddingBottom: space[9],
    position: `relative`,
    overflow: `hidden`,
    paddingLeft: 0,
    paddingRight: 0,
    [mediaQueries.phablet]: {
      width: `100%`,
      paddingLeft: space[7],
      paddingRight: space[7],
    },
    [mediaQueries.tablet]: {
      borderRadius: radii[1],
      display: `flex`,
      marginTop: space[12],
      marginBottom: space[12],
      paddingLeft: space[7],
      paddingRight: space[7],
      // width: `100%`,
    },
  },
  featuresInnerContainer: {
    paddingTop: space[12],
    paddingBottom: `16vh`,
    [mediaQueries.tablet]: {
      paddingTop: space[14],
      paddingBottom: space[14],
    },
  },
  customBodyCopy: {
    color: colors.howItWorks.base,
    maxWidth: rhythm(20),
    margin: `auto`,
    paddingBottom: space[9],
    textAlign: `center`,
  },
}

const HomeFeatures = ({ section, layout }) => (
  <Container
    customStyles={{ ...styles.featuresContainer }}
    innerCustomStyles={{ ...styles.featuresInnerContainer }}
  >
    <Title
      customStyles={{ color: colors.howItWorks.base, textAlign: `center` }}
    >
      {section.title}
    </Title>
    {section.text && section.text.childMarkdownRemark && (
      <div
        css={{
          ...bodyCopy,
          ...styles.customBodyCopy,
        }}
        dangerouslySetInnerHTML={{
          __html: section.text.childMarkdownRemark.html,
        }}
      />
    )}
    <Section section={section} layout={layout} />
    <CTA section={section} />
    <SkewedBackground shape="wave" position="bottom" height="4" />
  </Container>
)

HomeFeatures.propTypes = {
  section: PropTypes.object.isRequired,
  layout: PropTypes.object,
}

export default HomeFeatures
